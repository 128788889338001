import './sass/catalpa.scss';

import initializeHeader from './js/header';
import "./js/catalpians.js";

import KeenSlider from 'keen-slider';


window.setInputInvalid = function(elem, msg) {
    if(elem) {
        if (msg) elem.placeholder = msg;
        elem.parentElement.classList.add('invalid');
        elem.parentElement.classList.remove('valid');
        elem.nextElementSibling.querySelector('.cross').classList.remove('hidden');
        elem.nextElementSibling.querySelector('.check').classList.add('hidden');
    }
}

window.setInputValid = function(elem) {
    if(elem) {
        elem.parentElement.classList.add('valid');
        elem.parentElement.classList.remove('invalid');
        elem.nextElementSibling.querySelector('.check').classList.remove('hidden');
        elem.nextElementSibling.querySelector('.cross').classList.add('hidden');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initializeHeader();

    if (document.getElementsByClassName('projectpage').length || document.getElementsByClassName('blogpage').length) {
        document.getElementById("facebook-url").href=`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`;
        document.getElementById("x-url").href = `https://twitter.com/share?text=Check this out&url=${window.location.href}`;
        document.getElementById("linkedin-url").href=`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
    }

    window.copyUrl = () => {
        const shareLink = document.getElementById("copy-url");
        const tooltip = document.getElementsByClassName("cpy-tooltip")[0];
        navigator.clipboard.writeText(window.location.href).then(() => {
            tooltip.innerHTML = "Copied!";
        }).catch((err) => {
            tooltip.innerHTML = "Oops, something went wrong.";
        });
        shareLink.addEventListener("mouseout", () => tooltip.innerHTML="Copy link to share!");
    }

    if (document.getElementById("partners_and_supporters")) {
        new KeenSlider(
            "#partners_and_supporters",
            {
                loop: true,
                breakpoints: {
                    "(min-width:0px)": {slides: {perView: 1, spacing: 10}},
                    "(min-width:576px)": {slides: {perView: 2, spacing: 10}},
                    "(min-width:768px)": {slides: {perView: 3, spacing: 10}},
                    "(min-width:992px)": {slides: {perView: 4, spacing: 10}},
                    "(min-width:1200px)": {slides: {perView: 5, spacing: 10}},
                },
            },
            [
                (slider) => {                    
                    let timeout;
                    let mouseOver = false;
                    const clearNextTimeout = () => clearTimeout(timeout);
                    const nextTimeout = () => {
                        clearTimeout(timeout);
                        if (mouseOver) {
                            return;
                        }
                        timeout = setTimeout(() => {
                            const perView = slider.options.slides.perView;
                            slider.moveToIdx(slider.track.details.abs + perView, true);
                        }, 5000);
                    }
                    const mouseOverHandler = () => {
                        mouseOver = true;
                        clearNextTimeout();
                    };
                    const mouseOutHandler = () => {
                        mouseOver = false;
                        nextTimeout();
                    }
                    slider.on("created", () => {
                        slider.container.addEventListener("mouseover", mouseOverHandler);
                        slider.container.addEventListener("mouseout", mouseOutHandler);
                        nextTimeout();
                    })
                    slider.on("dragStarted", clearNextTimeout);
                    slider.on("animationEnded", nextTimeout);
                    slider.on("updated", nextTimeout);
                }
            ]
        );
    }

    if (document.getElementById("blogs-carousel")) {
        new KeenSlider(
            "#blogs-carousel",
            {
                loop: false,
                breakpoints: {
                    "(min-width:0px)": {slides: {perView: 1, spacing: 10}},
                    "(min-width:576px)": {slides: {perView: 2, spacing: 10}},
                    "(min-width:992px)": {slides: {perView: 3, spacing: 20}},
                    "(min-width:1200px)": {slides: {perView: 3, spacing: 40}},
                },
            },
            [
                (slider) => {
                    let dots;
                    let wrapper;

                    function markup(remove) {
                        wrapperMarkup(remove);
                        dotMarkup(remove);
                    }

                    function removeElement(element) {
                        element.parentNode?.removeChild(element);
                    }

                    function wrapperMarkup(remove) {
                        if (remove) {
                            let parent = wrapper.parentNode
                            while (wrapper.firstChild)
                                parent.insertBefore(wrapper.firstChild, wrapper);
                            removeElement(wrapper);
                            return;
                        }
                        wrapper = createDiv("slider-wrapper");
                        slider.container.parentNode.appendChild(wrapper);
                        wrapper.appendChild(slider.container);
                    }

                    function createDiv(className) {
                        let div = document.createElement("div")
                        let classNames = className.split(" ")
                        classNames.forEach((name) => div.classList.add(name))
                        return div;
                    }

                    function dotMarkup(remove) {
                        if (remove && dots) {
                            return removeElement(dots);
                        }
                        // if there is only 1 slide - no dots
                        // or the .perView is 3 (meaning all cards are visible) - no dots
                        // if the .perView is 2 and the total number of slides is 2 - no dots
                        if (
                            slider.track.details.slides.length <= 1 || 
                            slider.options.slides.perView == 3 ||
                            (slider.options.slides.perView == 2 && slider.track.details.slides.length <=2)
                        ) return;

                        dots = createDiv("dots");
                        slider.track.details.slides.forEach((_e, idx) => {
                            let dot = createDiv("dot");
                            dot.addEventListener("click", () => slider.moveToIdx(idx));
                            // if the .perView is 2 and index is 2 we want to return without adding a dot so we only have 2 dots for the 2 screens. 
                            if (slider.options.slides.perView == 2) {
                                if (idx != 2) {
                                    dots.appendChild(dot);
                                }
                                return;
                            }
                            else {
                                dots.appendChild(dot);
                            }
                        })
                        wrapper.appendChild(dots);
                    }

                    function updateClasses() {
                        let slide = slider.track.details.rel
                        if (!dots) return;
                        Array.from(dots.children).forEach(function (dot, idx) {
                            idx === slide
                            ? dot.classList.add("dot--active")
                            : dot.classList.remove("dot--active")
                        })
                    }

                    slider.on("created", () => {
                        markup();
                        updateClasses();
                    });

                    slider.on("optionsChanged", () => {
                        markup(true);
                        markup();
                        updateClasses()
                      });
                      
                    slider.on("slideChanged", updateClasses)
                }
            ]
        );
    }


    if (document.getElementById("three-image-carousel")) {
        new KeenSlider(
            "#three-image-carousel",
            {
                loop: true,
                breakpoints: {
                    "(min-width:0px)": { slides: { perView: 1 } },
                    "(min-width:768px)": { slides: { perView: 3 } },
                },
            },
            [
                (slider) => {
                    let wrapper, timeout, arrowLeft, arrowRight;
                    let mouseOver = false;
                    const clearNextTimeout = () => clearTimeout(timeout);
                    const nextTimeout = () => {
                        clearTimeout(timeout);
                        if (mouseOver) {
                            return;
                        }
                        if (slider.options.slides.perView !== 3) {
                            timeout = setTimeout(() => {
                                const perView = slider.options.slides.perView;
                                slider.moveToIdx(slider.track.details.abs + perView, true);
                            }, 5000);
                        }
                    };
                
                    const mouseOverHandler = () => {
                        mouseOver = true;
                        clearNextTimeout();
                    };
                
                    const mouseOutHandler = () => {
                        mouseOver = false;
                        nextTimeout();
                    };

                    function markup(remove) {
                        wrapperMarkup(remove);
                        arrowMarkup(remove);
                    };
                    
                    function removeElement(element) {
                        element.parentNode.removeChild(element);
                    };

                    function createDiv(className) {
                        let div = document.createElement("div");
                        if (className.includes("arrow--right")) {
                            div.innerHTML = "<i class='las la-arrow-right la-lg'></i>";
                        }
                        if (className.includes("arrow--left")) {
                            div.innerHTML = "<i class='las la-arrow-left la-lg'></i>";
                        }
                        let classNames = className.split(" ")
                        classNames.forEach((name) => div.classList.add(name))
                        return div;
                    };

                    function wrapperMarkup(remove) {
                        if (remove) {
                            let parent = wrapper.parentNode;
                            while (wrapper.firstChild) {
                                parent.insertBefore(wrapper.firstChild, wrapper);
                            };
                            removeElement(wrapper);
                            return;
                        };
                        wrapper = createDiv("slider-wrapper");
                        slider.container.parentNode.insertBefore(wrapper, slider.container);
                        wrapper.appendChild(slider.container);
                    };
                    
                    function arrowMarkup(remove) {
                        if (remove) {
                          removeElement(arrowLeft);
                          removeElement(arrowRight);
                          return;
                        }
                        arrowLeft = createDiv("arrow arrow--left");
                        arrowLeft.addEventListener("click", () => slider.prev());
                        arrowRight = createDiv("arrow arrow--right");
                        arrowRight.addEventListener("click", () => slider.next());
                    
                        wrapper.appendChild(arrowLeft);
                        wrapper.appendChild(arrowRight);
                    };
                    
                    slider.on("created", () => {
                        slider.container.addEventListener("mouseover", mouseOverHandler);
                        slider.container.addEventListener("mouseout", mouseOutHandler);
                        nextTimeout();
                        markup();
                    })
                    slider.on("dragStarted", clearNextTimeout);
                    slider.on("animationEnded", nextTimeout);
                    slider.on("optionsChanged", () => {
                        markup(true);
                        markup();
                    });

                    slider.on("updated", nextTimeout);
                }
            ]
        );
    }

    if (document.getElementById("products-carousel")) {
        new KeenSlider(
            "#products-carousel",
            {
                loop: false,
            },
            [
                (slider) => {
                    let wrapper, dots;

                    function markup(remove) {
                        wrapperMarkup(remove);
                        dotMarkup(remove);
                    }

                    function removeElement(element) {
                        element.parentNode?.removeChild(element);
                    }

                    function createDiv(className) {
                        let div = document.createElement("div");
                        let classNames = className.split(" ")
                        classNames.forEach((name) => div.classList.add(name))
                        return div;
                    }

                    function wrapperMarkup(remove) {
                        if (remove) {
                            let parent = wrapper.parentNode;
                            while (wrapper.firstChild)
                                parent.insertBefore(wrapper.firstChild, wrapper);
                            removeElement(wrapper);
                            return;
                        }
                        wrapper = createDiv("slider-wrapper");
                        slider.container.parentNode.appendChild(wrapper);
                        wrapper.appendChild(slider.container);
                    }

                    function dotMarkup(remove) {
                        if (remove && dots) {
                            return removeElement(dots);
                        }
                        // if there is only 1 slide - no dots
                        if (slider.track.details.slides.length <= 1) return;

                        dots = createDiv("dots");
                        slider.track.details.slides.forEach((_e, idx) => {
                            let dot = createDiv("dot");
                            dot.addEventListener("click", () => slider.moveToIdx(idx));
                            dots.appendChild(dot);
                        })
                        wrapper.appendChild(dots);
                    }

                    function updateClasses() {
                        if (!dots) return;
                        let slide = slider.track.details.rel;
                        Array.from(dots.children).forEach(function (dot, idx) {
                            idx === slide
                            ? dot.classList.add("dot--active")
                            : dot.classList.remove("dot--active")
                        })
                    }

                    slider.on("created", () => {
                        markup();
                        updateClasses();
                    });

                    slider.on("optionsChanged", () => {
                        markup(true);
                        markup();
                        updateClasses()
                      });
                      
                    slider.on("slideChanged", updateClasses)
                }
            ]
        );
    }

    if (document.getElementById("image-carousel")) {
        new KeenSlider(
            "#image-carousel",
            {
                breakpoints: {
                    "(min-width:0px)": {slides: {perView: 1}},
                    "(min-width:577px)": {slides: {perView: 1.5}},
                    "(min-width:768px)": {slides: {perView: 2.5}},
                    "(min-width:992px)": {slides: {perView: 3.5}},
                },
            },
            [
                (slider) => {
                    let wrapper, arrowLeft, arrowRight;

                    function markup(remove) {
                        wrapperMarkup(remove);
                        arrowMarkup(remove);
                    };

                    function removeElement(element) {
                        element.parentNode?.removeChild(element);
                    };

                    function createDiv(className) {
                        let div = document.createElement("div");
                        if (className.includes("arrow--right")) {
                            div.innerHTML = "<i class='las la-arrow-right la-lg'></i>";
                        };
                        if (className.includes("arrow--left")) {
                            div.innerHTML = "<i class='las la-arrow-left la-lg'></i>";
                        };
                        let classNames = className.split(" ");
                        classNames.forEach((name) => div.classList.add(name));
                        return div;
                    };

                    function wrapperMarkup(remove) {
                        if (remove) {
                            let parent = wrapper.parentNode;
                            while (wrapper.firstChild) {
                                parent.insertBefore(wrapper.firstChild, wrapper);
                            };
                            removeElement(wrapper);
                            return;
                        };
                        wrapper = createDiv("slider-wrapper");
                        slider.container.parentNode.appendChild(wrapper);
                        wrapper.appendChild(slider.container);
                    };

                    function arrowMarkup(remove) {
                        if (remove) {
                            removeElement(arrowLeft)
                            removeElement(arrowRight)
                            return;
                        };
                        arrowLeft = createDiv("arrow arrow--left");
                        arrowLeft.addEventListener("click", () => slider.prev());
                        arrowRight = createDiv("arrow arrow--right");
                        arrowRight.addEventListener("click", () => slider.next());
                    
                        wrapper.appendChild(arrowLeft);
                        wrapper.appendChild(arrowRight);
                    };

                    function updateClasses() {
                        if (slider.slides.length === 1) {
                            removeElement(arrowLeft);
                            removeElement(arrowRight);
                        } else {
                            let slide = slider.track.details.rel;
                            slide === 0
                                ? arrowLeft.classList.add("arrow--disabled")
                                : arrowLeft.classList.remove("arrow--disabled");
                            slide === slider.track.details.maxIdx
                                ? arrowRight.classList.add("arrow--disabled")
                                : arrowRight.classList.remove("arrow--disabled");
                        };
                    };

                    slider.on("created", () => {
                        markup();
                        updateClasses();
                    });

                    slider.on("optionsChanged", () => {
                        markup(true);
                        markup();
                        updateClasses();
                      });
                      
                    slider.on("slideChanged", updateClasses);
                }
            ]
        );
    }

    function activePill(id, activateAll = false) {
        document.querySelectorAll('[data-filter-bt]').forEach((bt) => {
            const filter = bt.getAttribute('data-filter-bt');
            if (id === 'all' && activateAll) {
                bt.classList.add("active");
            } else if (filter == id || id === undefined && filter === 'all') {
                bt.classList.add("active");
            } else {
                bt.classList.remove("active");
            };
        });
    }

    function filterDivList(listDivs, id, css_classes = false) {
        let visibleIndex = 1;
        for (const div of listDivs) {
            const idArray = div.getAttribute('data-filter-ids').split(',').filter(
                i => i.length
            ).map(
                i => parseInt(i, 10) 
            )
            const display = id === undefined || id === 'all' || idArray.includes(id);
            if (display) {
                div.removeAttribute("hidden");
            } else {
                div.setAttribute("hidden", true);
            };

            if (display && css_classes) {
                if (visibleIndex <= 2 ) {
                    div.classList.remove("col-lg-4");
                } else {
                    div.classList.add("col-lg-4");
                };
                visibleIndex++;
            };
        }
    }

    window.toggleButtons = (e) => {
        const target = e.currentTarget;
        if (target.classList.contains('draw-closed')) {
            target.classList.replace('draw-closed', 'draw-open');
        } else {
            target.classList.replace('draw-open', 'draw-closed');
        }
    }

    window.showButtons = (e) => {
        const target = e.currentTarget;
        const filters = document.getElementsByClassName("filters").item(0);
        
        if (target.classList.contains("draw-closed")) {
            target.classList.replace("draw-closed", "draw-open");
            target.querySelector("span").innerText = "Show less";
            target.querySelector("i").classList.replace("la-plus", "la-minus");
            filters.querySelectorAll("button").forEach((button) => button.classList.replace("d-md-none", "d-md-flex"));
        } else {
            target.classList.replace("draw-open", "draw-closed");
            target.querySelector("span").innerText = "Show more";
            target.querySelector("i").classList.replace("la-minus", "la-plus");
            filters.querySelectorAll("button").forEach((button) => button.classList.replace("d-md-flex", "d-md-none"));
        };
    };

    window.filterStories = (id) => {
        activePill(id);
        filterDivList(document.getElementById("stories-list").children, id);
    };

    window.scrollToContent = () => {
        window.scrollTo({
            top: (
                document.querySelector('main').children[0].offsetHeight
                - document.querySelector('nav').offsetHeight 
            ),
            behavior: 'smooth'
        })
    }

    // contact page functionality 
    const contactForm = document.getElementById('contact-form');
    let inputs;
    if (contactForm) {
        inputs = contactForm.querySelectorAll(":required");
        inputs.forEach((input) => {
            input.addEventListener("input", validateContactInput);
            input.addEventListener("blur", validateContactInput)
        });
    };


    function validateContactInput(e) {
        const fields = [...inputs];

        if (!e.target.validity.valid) {
            const error = e.target.name == 'email' 
                ? e.target.placeholder=`Please enter a valid ${e.target.name} address`
                : e.target.placeholder=`Please enter a ${e.target.name}`;
            setInputInvalid(e.target, error);
        }
        else {
            setInputValid(e.target);
        }

        let valids = fields.filter((field) => field.parentElement.classList.contains('valid'));
        return valids.length == fields.length ? document.getElementById('submit-btn').disabled=false : document.getElementById('submit-btn').disabled=true;
    }

    // set up the project list filtering
    if (document.getElementById("project-list")) {

        // All the impact area buttons
        const buttons = document.querySelectorAll(".filters .impact-pill");
        // All the project cards
        const projects = document.querySelectorAll(".project-card");

        // Modify the DOM applying the filter
        function applyFilter(filter) {
            buttons.forEach((button) => button.classList.toggle("active", filter === button.getAttribute("data-filter-bt")));

            let shownProjects = 0;
            projects.forEach((project) => {
                // hide or show the project card
                if (filter === "all" || project.getAttribute("data-filter-ids").split(",").some((ia) => ia === filter)) {
                    project.removeAttribute("hidden");
                    shownProjects += 1;
                    project.classList.toggle("col-lg-6", shownProjects <= 2);
                } else {
                    project.setAttribute("hidden", "");
                };
            });
        };

        // button click handling
        buttons.forEach((button) => {
            button.addEventListener("click", (e) => {
                const filter = e.currentTarget.getAttribute("data-filter-bt");
                applyFilter(filter);
                // build and push the new URL
                const url = new URL(location);
                if (filter !== "all") {
                    url.searchParams.set("impact_area", filter);
                } else {
                    url.searchParams.delete("impact_area");
                };
                history.pushState({ impact_area: filter }, "", url);
            });
        });

        // react to pop state events to handle back/forward
        window.addEventListener("popstate", (e) => applyFilter(e.state.impact_area || "all"));

        // on initial page load set the state from the URL to allow easy pop handling, and apply the filter
        const initialFilter = new URLSearchParams(window.location.search).get("impact_area") || "all";
        history.replaceState({impact_area: initialFilter}, "");
        applyFilter(initialFilter);
    }
});

if (module.hot) {
    module.hot.accept('./js/header.js', function () {
        initializeHeader();
    });
}

/**
 * Catalpaians Functionality
 * - React to opening a catalpian modal by populating the modal content
 * - See more clicking...
 */

import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
    catalpianModal();
    if (document.querySelector('.container-catalpians a.more')) {
        setNumberOfCatalpaians();
        window.addEventListener('resize', setNumberOfCatalpaians);
    } else {
        window.removeEventListener('resize', setNumberOfCatalpaians);
    };
});

function setNumberOfCatalpaians() {
    let windowWidth = window.innerWidth;

    const catalpians = [...document.querySelectorAll('.catalpianWrapper')];

    if (windowWidth < 768) {
        catalpians.forEach((catalpian, index) => {
            if (index > 3) {
                catalpian.setAttribute("hidden", true);
            } else {
                catalpian.removeAttribute("hidden");
            };
        });
    } else if ((windowWidth >= 768) && (windowWidth < 992)) {
        catalpians.forEach((catalpian, index) => {
            if (index > 5) {
                catalpian.setAttribute("hidden", true);
            } else {
                catalpian.removeAttribute("hidden");
            };
        });
    } else if ((windowWidth >= 992) && (windowWidth < 1200)) {
        catalpians.forEach((catalpian, index) => {
            if (index > 7) {
                catalpian.setAttribute("hidden", true);
            } else {
                catalpian.removeAttribute("hidden");
            };
        });
    } else {
        catalpians.forEach((catalpian, index) => {
            if (index > 9) {
                catalpian.setAttribute("hidden", true);
            } else {
                catalpian.removeAttribute("hidden");
            };
        });
    };

    catalpianSeeMore();
}

function catalpianSeeMore() {
    const moreLink = document.querySelector('.container-catalpians a.more');
    const catalpians = Array.from(document.querySelectorAll('.container-catalpians .catalpianWrapper') || []);
    const hiddenCatalpians = catalpians.filter((catalpian) => catalpian.hasAttribute("hidden"));

    if (!moreLink) {
        return;
    }

    function showHideLink() {
        if (hiddenCatalpians.filter((catalpian) => catalpian.hidden).length) {
            moreLink.removeAttribute("hidden");
        } else {
            moreLink.setAttribute("hidden", true);
        }
    };
    showHideLink();

    moreLink.addEventListener('click', function (event) {
        hiddenCatalpians.forEach((block) => {
            block.removeAttribute("hidden");
        });
        showHideLink();
    });
}

/** On opening a modal set it's content to the catalpian that was clicked */
function catalpianModal() {
    const catalpianModal = document.getElementById('catalpianModal');
    if (!catalpianModal) {
        return;
    }

    const blockElems = {
        img: 'img',
        title: '.persons-title',
        name: '.persons-name',
        bio: '.bio',
        linkedin: '.linkedin',
    }

    // related to content elements found in templates/home/catalpian_modal.html
    const modalElements = {};
    Object.entries(blockElems).forEach(([key, selector]) => {
        modalElements[key] = catalpianModal.querySelector(selector);
    });

    catalpianModal.addEventListener('show.bs.modal', function (event) {
        const button = event.relatedTarget;
        const catalpian = button.closest('.block-catalpian');

        // related to elements found in templates/blocks/catalpian.html
        const catalpianElements = {};
        Object.entries(blockElems)
        .forEach(([key, selector]) => {
            catalpianElements[key] = catalpian.querySelector(selector);
        });

        // update the modal image src
        modalElements.img.setAttribute("src",
            catalpianElements.img.getAttribute("src")
        );

        // update the modal text elements
        modalElements.name.innerText = catalpianElements.name.innerText;
        modalElements.title.innerText = catalpianElements.title.innerText;

        // update the modal bio html
        modalElements.bio.innerHTML = catalpianElements.bio.innerHTML;

        // update and show or hide links
        if (catalpianElements.linkedin) {
            modalElements.linkedin.setAttribute('href',
                catalpianElements.linkedin.getAttribute("href")
            );
            modalElements.linkedin.removeAttribute("hidden");
        } else {
            modalElements.linkedin.setAttribute("hidden", true);
        }
    });
}
